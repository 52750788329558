.landing-page {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

/*header {*/
/*    background: url('../public/images/LP- Blue with icons in hexagons (1080 x 500 px).png') left/cover no-repeat;*/
/*    height: 800px;*/
/*    !*color: white;*!*/
/*    text-align: center;*/
/*    padding: 4rem 0;*/
/*}*/

header {
    background-color: #009688;
    color: white;
    text-align: center;
    padding: 2rem 0;
}

/*header h1 {*/
/*    margin: 10rem;*/
/*    font-size: 3.5rem;*/
/*    font-weight: bold;*/
/*    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); !* Add text shadow for better contrast *!*/
/*}*/

header h1 {
    margin: 0;
    font-size: 2.5rem;
}

main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

/* Hero Section */

.hero {
    text-align: center;
    margin-bottom: 3rem;
    /*background-image: url("../public/images/hero_section_resized_image.png");*/

}

.hero h2 {
    font-size: 2rem;
    color: #009688;
}

.hero h2,
.highlights h2,
.about h2,
.modal h2 {
    text-align: center;
    color: #009688; /* This uses your accent color */
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
}

button {
    background-color: #009688;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #00796b;
}

.highlights {
    margin-bottom: 3rem;
}

.highlight-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
}

.highlight-item {
    text-align: center;
}

.highlight-icon {
    font-size: 3rem;
    color: #009688;
}

.about {
    background-color: #f5f5f5;
    padding: 2rem;
    border-radius: 8px;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem 0;
}

@media (max-width: 768px) {
    .highlight-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 0rem;
    }
    .highlight-item {
        overflow: hidden;
    }
}

@media (max-width: 480px) {
    .highlight-grid {
        grid-template-columns: 1fr;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
        overflow: hidden;
    }
}

/* Add these styles to your existing CSS */

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

nav ul li {
    margin: 0 1rem;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

nav ul li a:hover {
    text-decoration: underline;
}

.feature-banner {
    background-color: #f5f5f5;
    padding: 2rem;
    margin: 2rem 0;
    border-radius: 8px;
    text-align: center;
}

.feature-icon {
    font-size: 4rem;
    display: block;
    margin-bottom: 1rem;
}

.feature-banner h3 {
    color: #009688;
    margin-bottom: 1rem;
}

/* Smooth scrolling for anchor links */
html {
    scroll-behavior: smooth;
}

/* Add these styles to your existing CSS */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.modal h2 {
    margin-top: 0;
}

.modal form {
    display: flex;
    flex-direction: column;
}

.modal label {
    margin-top: 1rem;
}

.modal input,
.modal textarea {
    padding: 0.5rem;
    margin-top: 0.5rem;
}

.modal button[type="submit"] {
    margin-top: 1rem;
}

.close-modal {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}
